'use client';

import { IPhilosophyComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useSetAtom } from 'jotai';
import React from 'react';
import Button, { ButtonVariants } from '../../components/Button';
import Img from '../../components/Img';
import contactModalTriggerButton from '../../store/contactForm';
import styles from './CompanyPhilosophy.module.scss';

const CompanyPhilosophy = ({ data }: { data: IPhilosophyComponent }) => {
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  return (
    <section className={classnames(styles.section)}>
      <div className='container'>
        <div
          className={styles.ellipse}
        >
          <Img
            src='/media/company/ellipse_hero.svg'
            alt='ellipse'
          />
        </div>
        <h2 className={classnames(styles.title, 'title3')}>
          {data.title}
        </h2>
        <p className={classnames('bodyText', styles.text)}>{data.description}</p>
        <Button
          className={styles.button}
          variant={ButtonVariants.PRIMARY}
          onClick={(e) => {
            setContactsModalTrigger(`${(e.target as HTMLElement).innerText} | Company Philosophy section`);
          }}
          icon={(
            <svg>
              <use
                xlinkHref='/media/icon-nest.svg#iconNest'
                href='/media/icon-nest.svg#iconNest'
              />
            </svg>
          )}
          iconPosition='right'
          iconSize={{ width: 24, height: 24 }}
        >
          Join us
        </Button>
      </div>
    </section>
  );
};

export default CompanyPhilosophy;

'use client';

import React, { useState } from 'react';
import { IPeopleGrowthComponent } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import Button, { ButtonVariants } from '../../components/Button';
import Img from '../../components/Img';
import contactModalTriggerButton from '../../store/contactForm';
import { theme } from '../../store/theme';
import styles from './CompanyGrowth.module.scss';

type CompanyGrowthProps = {
  data: IPeopleGrowthComponent
};
const CompanyGrowth = ({ data }: CompanyGrowthProps) => {
  const appTheme = useAtomValue(theme);
  const [activeCard, setActiveCard] = useState(0);
  const setContactsModalTrigger = useSetAtom(contactModalTriggerButton);

  return (
    <section className={classnames(styles.growth, 'container')}>
      <h2 className={classnames(styles.title, 'title3')}>{data.title}</h2>
      <div className={styles.growth__content}>
        {data.items.map((card, index) => (
          <div
            key={card.tabName}
            role='button'
            tabIndex={0}
            onClick={() => setActiveCard(index)}
            onKeyDown={(e) => (e.keyCode === 13 || e.keyCode === 32) && setActiveCard(index)}
            className={classnames(styles.card, {
              [styles.active]: index === activeCard,
            })}
          >
            <div className={styles.card__mainLine}>
              <div className={classnames(styles.card__icon, styles.card__icon_mobile)}>
                <Img
                  src={card[`${appTheme}Image`].url}
                  alt={card[`${appTheme}Image`].alternativeText || card.tabName}
                />
              </div>
              <div>
                <p className={styles.index}>
                  0
                  {index + 1}
                </p>
                <p className={styles.card__title}>{card.tabName}</p>
              </div>
            </div>
            <div className={styles.card__accordeon}>
              <div className={styles.card__content}>
                <div className={styles.card__icon}>
                  <Img
                    src={card[`${appTheme}Image`].url}
                    alt={card[`${appTheme}Image`].alternativeText || card.tabName}
                  />
                </div>
                <div>
                  <p className={styles.card__subtitle}>{card.title}</p>
                  <p className={classnames(styles.card__text, 'bodyText')}>{card.description}</p>
                  <Button
                    tabIndex={index === activeCard ? 0 : -1}
                    variant={ButtonVariants.OUTLINED}
                    className={styles.ctaButton}
                    icon={(
                      <svg>
                        <use
                          xlinkHref='/media/icon-nest.svg#iconNest'
                          href='/media/icon-nest.svg#iconNest'
                        />
                      </svg>
                    )}
                    iconPosition='right'
                    iconSize={{ width: 32, height: 32 }}
                    onClick={(e) => {
                      setContactsModalTrigger(`${(e.target as HTMLElement).innerText} | Company Growth section`);
                    }}
                  >
                    Join Us
                  </Button>
                  <div
                    className={styles.ellipse}
                  >
                    <Img
                      src='/media/company/ellipse_hero.svg'
                      alt='ellipse'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CompanyGrowth;
